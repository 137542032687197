.app_screen {
  /* padding: 2rem 1rem; */
  height: 100vh;
  width: 100vw;
  font-family: "Roboto", sans-serif;
}
.app_wrapp {
  padding: 1rem;
  background: #fff;
  /* background: linear-gradient(to bottom, #0000000d, #ffffff); */
  border-radius: 15px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* justify-content: flex-start; */
  flex-direction: column;
  padding-top: 4rem;
}
.app_screen_top {
  text-align: center;
}
.app_screen_top p {
  margin-bottom: 1.5rem;
  letter-spacing: 2px;
  font-size: 14px;
  color: #9a9a9a;
}
.app_screen_top img {
  height: 50px;
}
.app_screen_text {
  margin-top: 2rem;
  text-align: center;
}
.app_screen_text p {
  font-size: 14px;
  color: #9a9a9a;
  line-height: 20px;
}
.app_screen_middle {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.downloadCard {
  /* box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px; */
  border-radius: 30px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin: 0 6px 0px 0px; */
}
.downloadCard img {
  border-radius: 0px;
}
.downloadCard.two {
  /* margin: 0 0px 0px 6px !important; */
}
.downloadCard span {
  font-size: 12px;
}
.downloadCard svg {
  font-size: 1.5rem;
  margin-right: 12px;
  /* color: #2e9d5e; */
  color: #ffc107;
}
.downloadCard h6 {
  font-weight: 400;
  margin-top: 2px;
}
.main_bg {
  /* position: absolute; */
  /* bottom: 0; */
  /* left: 0; */
}
