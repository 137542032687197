.staticData h5 {
  margin-top: 1rem;
  margin-bottom: 2px;
}
.gp_head {
  font-weight: 400 !important;
  color: #7e7e7e;
  font-size: 26px !important;
}
.section-title {
  margin-bottom: 30px;
}
.banner-img.style-3 {
  background: none;
}
.detail-extralink {
  margin: 0 10px !important;
}
.navIcon2 {
  border-radius: 50%;
  position: absolute;
  top: -6px;
  right: -9px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.font-normal {
  font-size: 14px !important;
}
.font-mainHead {
  font-size: 18px !important;
}
.font-head {
  font-size: 16px !important;
}
.font-small {
  font-size: 13px !important;
}
.font-bigger {
  font-size: 32px !important;
}
.infinite-scroll-component {
  /* overflow: inherit !important; */
  /* overflow: unset !important; */
  overflow-x: hidden !important;
}
.color-darkGreen {
  color: #3bb77e !important;
}
.bg-darkGreen {
  background-color: #3bb77e !important;
}
.active_tag {
  background-color: #c5eada !important;
  color: #000 !important;
}

.product-cart-wrap .product-img-action-wrap {
  height: 130px;
  padding: 10px 12px 0 12px;
}
.product-cart-wrap .product-img-action-wrap .product-img a img {
  object-fit: contain;
}
.product-cart-wrap .product-img-action-wrap .product-img,
.product-cart-wrap .product-img-action-wrap .product-img a {
  height: 100%;
}

.titles {
  position: absolute;
  top: 0;
  padding: 2rem;
}

.background_image {
  position: relative;
  cursor: pointer;
}
.product-cart-wrap .product-img-action-wrap .product-img a img.hover-img {
  height: 100%;
}
.collection_swipe {
  width: 400px;
  height: 200px;
  position: relative;
}
.collection_swipe a {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 99;
  font-size: 16px;
}
.collection_swipe img {
  height: 165px;
  border-radius: 10px;
}
.card-2 figure img {
  max-width: 60px;
}
.onlyOneBanner ~ .slider-arrow .custom_prev_i1,
.onlyOneBanner ~ .slider-arrow .custom_next_i1 {
  display: none !important;
}

.custom_category_label {
  border: none !important;
  box-shadow: none !important;
}
.custom_category_label ul {
  display: flex;
}
.custom_footer {
  background: #f4f6fa;
  padding: 2rem;
  border-radius: 15px;
}
.categories-dropdown-active-large {
  min-width: 600px;
}
.bg-yellow {
  background-color: #f5d646 !important;
}
.bg-yellow-light {
  background-color: #fceca6 !important;
  color: #2e9d5e;
}
.text-green {
  color: #2e9d5e !important;
}
.underline {
  text-decoration: underline;
}
.mySlider .image_wrapper {
  padding-bottom: 0 !important;
}

.header-action-icon-2 {
  height: 25px !important;
}
.scroll-300 {
  max-height: 300px;
  overflow-y: scroll;
}
.white-space-nowrap {
  white-space: nowrap;
}
.opacity-half {
  opacity: 0.5;
}

.break {
  word-break: break-all !important;
}
.curvy {
  border-radius: 15px 0px 15px 0px !important;
}
.rounded-xs {
  border-radius: 10px !important;
}
.rounded-sm {
  border-radius: 15px !important;
}
.rounded-md {
  border-radius: 20px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}
.cursor-notAllowed {
  cursor: not-allowed !important;
}
/* //hieght */
.h\:80px {
  height: 80px !important;
}
.w\:80px {
  width: 80px !important;
}
.h-fit {
  height: fit-content;
}
.h\:60px {
  height: 60px !important;
}
.h\:40px {
  height: 40px !important;
}
.h\:100px {
  height: 100px;
}
.h\:130px {
  height: 130px;
}
.h\:150px {
  height: 150px !important;
}
.h\:200px {
  height: 200px !important;
}
.h\:160px {
  height: 160px !important;
}
.h\:300px {
  height: 300px !important;
}
.h\:250px {
  height: 250px !important;
}
.h-95 {
  height: 95% !important;
}
.h-90 {
  height: 90% !important;
}
.h\:50px {
  height: 50px !important;
}
.h-half {
  height: 50vh !important;
}
.max-h\:200px {
  max-height: 200px !important;
}
.max-h\:300px {
  max-height: 300px !important;
}
.max-h\:400px {
  max-height: 400px !important;
}
.min-h-none {
  min-height: none !important;
}
.min-h-auto {
  min-height: auto !important;
}
.min-h-100px {
  min-height: 100px !important;
}
.min-h-260px {
  min-height: 260px !important;
}
.min-h-320px {
  min-height: 320px !important;
}

/* width */
.min-w\:40px {
  min-width: 40px;
}
.min-w\:80px {
  min-width: 80px;
}
.min-w\:180px {
  min-width: 180px;
}
.min-w\:150px {
  min-width: 150px;
}
.min-w\:100px {
  min-width: 100px;
}
.max-w\:700px {
  max-width: 700px;
}
.max-w\:600px {
  max-width: 600px;
}
.max-w\:200px {
  max-width: 200px;
}
.max-w\:250px {
  max-width: 250px;
}
.max-w\:300px {
  max-width: 300px !important;
}
.max-w\:400px {
  max-width: 400px;
}
.max-w\:500px {
  max-width: 500px;
}
.w\:100px {
  width: 100px;
}

.w\:150px {
  width: 150px !important;
}

.w-fit {
  width: fit-content !important;
}
.w\:50px {
  width: 50px !important;
}
.w\:60px {
  width: 60px !important;
}

.w-70\% {
  width: 70% !important;
}

.w-90\% {
  width: 90% !important;
}

.w-20\% {
  width: 20% !important;
}
.w-50\% {
  width: 50% !important;
}
.min-w-none {
  min-width: none !important;
}

/* //border */
.border-none {
  border: none !important;
}

.border-b-4 {
  border-bottom: 1px solid #e9ecef !important;
}
.border-1-gray {
  border: 1px solid #ececec;
}

/* padding */
.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.p-6 {
  padding: 6rem !important;
}
.p-2 {
  padding: 2rem !important;
}
.p-1 {
  padding: 1rem !important;
}
.p-1\.5 {
  padding: 1.5rem !important;
}
/* margin */
.mr-1 {
  margin-right: 1rem !important;
}
.mr-2 {
  margin-right: 2rem !important;
}
.mr-5px {
  margin-right: 5px !important;
}

.ml-auto {
  margin-left: auto !important;
}
.ml-1\.5rem {
  margin-left: 1.5rem !important;
}
.ml-1rem {
  margin-left: 1rem !important;
}
.ml-0\.5rem {
  margin-left: 0.5rem !important;
}
.mx-1 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

/* //background */
.bg-transperant {
  background-color: transparent !important;
}
.bg-pista {
  background-color: #ebf7ef !important;
}
.bg-lightgreen {
  background-color: #3bb77e !important;
}
.bg-black {
  background-color: #333 !important;
}
.bg-disable {
  background-color: #787878 !important;
}
.fit-cover {
  object-fit: cover !important;
}
.fit-contain {
  object-fit: contain !important;
}

/* font size */
.text-size-12 {
  font-size: 12px !important;
}

.text-size-14 {
  font-size: 14px !important;
}
.text-size-16 {
  font-size: 16px !important;
}
.text-size-20 {
  font-size: 20px !important;
}

.text-end {
  text-align: end;
}
.text-center {
  text-align: center !important;
}

.text-dthYellow {
  color: #f4d644 !important;
}
.text-green {
  color: #00c851;
}
.text-danger {
  color: #ff4444;
}
.text-info {
  color: #33b5e5;
}
.text-osperb {
  color: #17618f !important;
}
.text-default {
  color: #2bbbad;
}
.text-primary {
  color: #4285f4;
}
.text-white {
  color: #fff;
}
.text-black {
  color: #335243 !important;
}
.text-gray {
  color: #787878 !important;
}
.bg-gray {
  background-color: #787878 !important;
}
.line-h-16px {
  line-height: 16px !important;
}
.font-bold {
  font-weight: bold;
}

/* position */
.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}
.right-0 {
  right: 0;
}
.left-0 {
  left: 0;
}
.right-10 {
  right: 10px !important;
}
.right-20 {
  right: 20px !important;
}
.right-40 {
  right: 40px !important;
}
.right-50 {
  right: 50px !important;
}
.right-60 {
  right: 60px !important;
}
.right-70 {
  right: 70px !important;
}
.right-80 {
  right: 80px !important;
}

.top-1rem {
  top: 1rem !important;
}
.left-1rem {
  left: 1rem !important;
}
.top-40 {
  top: 40% !important;
}

.bottom-0 {
  bottom: 0 !important;
}
.bottom-40 {
  bottom: 40px !important;
}
.bottom-45 {
  bottom: 45px !important;
}
.bottom-50 {
  bottom: 50px !important;
}
/* /////// */
.custom-class .swiper-wrapper {
  height: 185px;
}

.boxshadow-4 {
  box-shadow: 0 4px 30px rgb(0 0 0 / 10%);
}

.zIndex-full {
  z-index: 99 !important;
}

.custom_banner {
  height: 300px;
  width: 300px;
  margin-right: 1rem;
}

.table-border-tr-none tbody tr {
  border: none !important;
}
.table-border-none tbody tr td {
  border: none !important;
}
.table-border-none tbody tr {
  border: none !important;
}
.custom_form_login {
  width: 40%;
}
#notification_modal .modal-dialog {
  max-width: 300px !important;
  width: auto !important;
  transition: all 0.3s ease-in-out;
}

#notification_modal .modal-dialog.center {
  top: 3rem !important;
}
#notification_modal .modal-dialog.center.hide {
  top: -5rem !important;
}
#notification_modal .modal-dialog.topRight {
  margin-right: 10px !important;
  top: 3rem !important;
}
#notification_modal .modal-dialog.topRight.hide {
  margin-right: -20rem !important;
  top: 3rem !important;
}
#notification_modal .modal-dialog.topLeft {
  margin-left: 10px !important;
  top: 3rem !important;
}
#notification_modal .modal-dialog.topLeft.hide {
  margin-left: -20rem !important;
  top: 3rem !important;
}
#notification_modal .modal-dialog.bottomRight {
  margin-right: 10px;
  top: 90%;
}
#notification_modal .modal-dialog.bottomRight.hide {
  margin-right: -20rem;
  top: 90%;
}
#notification_modal .modal-dialog.bottomLeft {
  margin-left: 10px;
  top: 90%;
}
#notification_modal .modal-dialog.bottomLeft.hide {
  margin-left: -20rem;
  top: 90%;
}
#notification_modal .modal-content {
  padding: 5px !important;
  border-radius: 10px !important;
  border: none !important;
}
#notification_modal .modal-body {
  padding: 0.5rem !important;
}

.wrapper_otp div {
  display: flex;
  justify-content: space-between;
}
.wrapper_otp input:last-child {
  margin-right: 0 !important;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.spinner {
  animation: spin 1s infinite linear;
}
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.custom-border {
  border-radius: 0 0 25px 25px !important;
}

.tada {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.tada:hover {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@-webkit-keyframes tada {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes tada {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    -ms-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    -ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    -ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  100% {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.tada {
  -webkit-animation-name: tada;
  animation-name: tada;
}

.shake {
  animation: shake 1.5s both infinite;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-0.5px, 0, 0), scale(1.2);
  }

  20%,
  80% {
    transform: translate3d(0.5px, 0, 0) scale(1);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-0.5px, 0, 0) scale(1.2);
  }

  40%,
  60% {
    transform: translate3d(0.5px, 0, 0) scale(1);
  }
}

.penTag {
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: -36px;
  right: 20px;
  background: #ffffff;
  padding: 10px;
  border-radius: 20px 20px 0px 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.swiper-button-next,
.swiper-button-prev {
  color: #000 !important;
}
@media screen and (max-width: 768px) {
  .flex-revers-col {
    flex-direction: column-reverse;
  }
}
@media screen and (max-width: 992px) {
  .md\:mb-2 {
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 480px) {
  .sm\:flex-column {
    flex-direction: column !important;
  }
  .locations_text {
    margin: 0 !important;
    text-align: start;
    width: 100%;
    padding-top: 15px;
  }
  .sm\:m-0 {
    margin: 0 !important;
  }
  .sm\:mt-4 {
    margin-top: 2rem !important;
  }
  .sm\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .sm\:mx-0\.5 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .sm\:max-w\:160px {
    max-width: 160px !important;
  }
  .sm\:mb-2 {
    margin-bottom: 1rem !important;
  }
  .sm\:text-start {
    text-align: start !important;
  }
  .sm\:mb-30 {
    margin-bottom: 30px;
  }
  .custom_small_slider {
    flex-direction: column;
  }
  .custom_form_login {
    width: 100%;
  }
  .sm\:bg-image-none {
    background-image: none !important;
  }

  .custom_small_slider .custom_banner {
    width: 100% !important;
    height: 200px !important;
  }

  .sm\:w-100-pre {
    width: 100% !important;
  }
  .table td {
    display: table-cell !important;
    /* width: fit-content !important; */
    text-align: start !important;
  }
  .invoice .table td {
    display: table-cell !important;
    /* width: fit-content !important; */
    text-align: start !important;
  }
  .sm\:pt-4 {
    padding-top: 2rem !important;
  }
  .sm\:pb-1 {
    padding-bottom: 1rem !important;
  }
  .sm\:flex-row {
    flex-direction: row !important;
  }
  .sm\:w-100 {
    width: 100%;
  }
  .sm\:w-50 {
    width: 50% !important;
  }
  .sm\:w-49 {
    width: 49%;
  }
  .sm\:justify-content-between {
    justify-content: space-between;
  }
  .sm\:mb-10 {
    margin-bottom: 10px !important;
  }
  .sm\:mb-20 {
    margin-bottom: 20px !important;
  }
  .sm\:d-none {
    display: none;
  }
  .sm\:p-4 {
    padding: 2rem;
  }
}
@media screen and (max-width: 820px) {
  .md\:mt-50 {
    margin-top: 50px !important;
  }
}
.modal-dialog {
  width: 80% !important;
}
.boxshadow-1 {
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px; */
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  /* border-radius: 5px; */
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}
.background_image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  /* height: 540px; */
  padding: 0 !important;
}
.background_image img {
  max-height: 550px;
}
.object-start {
  object-position: left;
}
.object-end {
  object-position: right;
}
.cart-bottom-noti {
  box-shadow: 0px 17px 20px 0px rgb(0 0 0 / 75%);
  transform: translateY(7rem);
  transition: all ease-in-out 0.3s;
}
.cart-bottom-noti.show {
  transform: translateY(0rem);
  transition: all ease-in-out 0.3s;
}
.cart-bottom-noti ul li .shopping-cart-title h4 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  margin: 0 0 9px;
}
.cart-bottom-noti li {
  height: 100% !important;
}

.cart-bottom-noti ul li .shopping-cart-title h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  margin: 0 0 0px;
  color: #3bb77e;
}
.swiper-wrapper {
  align-items: center !important;
}
.main_image_slider {
  height: 442px !important;
  width: 442px !important;
  object-fit: contain;
  margin: auto;
}
.checkout-items-border {
  border-bottom: 1px solid #e9ecef !important;
  margin-bottom: 10px !important;
}
.line_load {
  width: 30px;
  background: #b6b6b6;
  height: 2px;
  margin-top: 5px;
  animation: width 2s ease-in-out infinite;
  transition: all 0.3s ease;
}

@keyframes width {
  0% {
    width: 0px;
  }
  50% {
    width: 20px;
  }
  100% {
    width: 30px;
  }
}
.order_limit .sort-by-product-wrap {
  display: flex !important;
}
